import React from 'react';
import NewsletterModal from '../components/NewsletterModal';

export default function BlogSocial() {
  const twitterBtnStyles =
    'bg-twitter font-title text-lg md:text-xl text-white font-semibold opacity-90 py-1 mb-4 focus:outline-none';
  const discordBtnStyles =
    'bg-gray-600 font-title text-lg md:text-xl text-white font-semibold opacity-90 py-1 mb-4 focus:outline-none';
  const hoverStyles = 'hover:opacity-80 transition-transform transform hover:-translate-y-0.5';
  const focusStyles = 'focus:bg-buttonRed';

  return (
    <div className="flex flex-col py-16 md:mb-8 sm:flex-row sm:justify-evenly">
      {/* Botão Twitter */}
      <button className={`${twitterBtnStyles} ${hoverStyles} ${focusStyles} sm:w-1/2 sm:mr-4`}>
        <a className="no-underline" href="https://x.com/KineraNetwork" target="_blank" rel="noreferrer">
          <div className="flex flex-row justify-between items-center">
            <div className="bg-black bg-opacity-10 py-1 px-3">
              <svg
                className="w-auto h-6 fill-current"
                role="img"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Twitter icon</title>
                <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
              </svg>
            </div>
            <div className="w-full">Follow Kinera on X</div>
          </div>
        </a>
      </button>

      {/* Botão Discord */}
      <button className={`${discordBtnStyles} ${hoverStyles} ${focusStyles} sm:w-1/2 sm:mx-4`}>
        <a className="no-underline" href="https://discord.com/invite/YourInviteCode" target="_blank" rel="noreferrer">
          <div className="flex flex-row justify-between items-center">
            <div className="bg-black bg-opacity-10 py-1 px-3">
              <svg
                className="w-auto h-6 fill-current"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Discord icon</title>
                <path d="M19.54 4.569c-1.082-.467-2.24-.8-3.46-.992a.114.114 0 0 0-.12.065c-.288.5-.6 1.157-.83 1.64-1.667-.25-3.333-.25-4.986 0-.232-.48-.552-1.14-.84-1.64a.116.116 0 0 0-.12-.064 15.79 15.79 0 0 0-3.46.991.106.106 0 0 0-.054.045c-2.266 3.356-2.88 6.614-2.566 9.838a.12.12 0 0 0 .045.085c2.09 1.532 4.118 2.469 6.1 3.08a.12.12 0 0 0 .132-.043c.47-.632.888-1.3 1.25-2.002a.12.12 0 0 0-.063-.164c-.666-.252-1.3-.56-1.91-.91a.118.118 0 0 1-.012-.197c.128-.095.256-.192.38-.29a.116.116 0 0 1 .12-.016c2.926 1.344 6.095 1.344 9.004 0a.116.116 0 0 1 .122.016c.124.098.252.195.38.29a.118.118 0 0 1-.012.197c-.606.352-1.24.66-1.91.91a.12.12 0 0 0-.063.165c.364.702.782 1.37 1.25 2.002a.12.12 0 0 0 .132.043c1.988-.61 4.015-1.548 6.1-3.08a.12.12 0 0 0 .045-.085c.47-4.282-.786-8.004-3.54-11.217a.106.106 0 0 0-.054-.045zM8.66 12.476c-1.166 0-2.118-.992-2.118-2.214 0-1.223.916-2.215 2.118-2.215 1.21 0 2.17 1.008 2.118 2.215 0 1.222-.916 2.214-2.118 2.214zm7.702 0c-1.166 0-2.118-.992-2.118-2.214 0-1.223.916-2.215 2.118-2.215 1.21 0 2.17 1.008 2.118 2.215 0 1.222-.916 2.214-2.118 2.214z"></path>
              </svg>
            </div>
            <div className="w-full">Join Us on Discord</div>
          </div>
        </a>
      </button>

      {/* Modal Newsletter */}
      <NewsletterModal sourcePage="blog-template" />
    </div>
  );
}